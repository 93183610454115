import React, { useState, useEffect, useRef, useMemo } from 'react'
import { getTotalCounts, getSourceIpData, getCyTableData, getSourcePortsData, getDestinationPortsData, getDestinationIpData, getCounts, getWidgetsData } from '../../../../Methods/InvestigationMethods'
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Chart } from "react-google-charts";
import NoDataFound from '../../../../components/NoDataFount/NoDataFound';
import { Bar, Doughnut } from "react-chartjs-2";
import { Button, Tab, Table, Tabs } from 'react-bootstrap';
import TableLoader from '../../../../components/Loader/TableLoader';
import { getDateFromTimestamp, getTimeFromTimestamp } from '../../../../Utils/Utils'
import styles from '../../Cykit/CykitInvestigation.styles';
import BarGraph from '../../../../components/Charts/BarGraph';
import { GridFour, Monitor, SquaresFour, Table as TableIcon } from 'phosphor-react';
import AddFilter from '../../../../components/AddFilter/AddFilter';
import useOnclickOutside from 'react-cool-onclickoutside';
import { fpostDl } from '../../../../axios/axios';
import FilterList from '../../../../components/FilterList/FilterList';
import InlineFilter from '../../../../components/InlineFilter/InlineFilter';
import { ArrowsOutSimple } from "phosphor-react";
import ExpandedDocument from '../../../../components/ExpandedDocument/ExpandedDocument';
import Pagination from '../../../../components/Pagination/Pagination'
import PlainCardLoader from '../../../../components/Loader/PlainCardLoader';
import CykitINVExport from './Export';
import { isEmptyObject } from '../../../../Utils/Utils';
import { getFirwallTableQuery } from '../../../../Queries/CyKitInvestigateQuery';
import moment from 'moment';
// ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
const datalakeURL = localStorage.getItem("DATALAKE_URL");
const INVESTIGATE_CYF = localStorage.getItem("INVESTIGATE_CYF");

const CyFirewall = () => {

    // pagination
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(100);
    const [currentPageCount, setCurrentPageCount] = useState(perPage);
    const [totalPageCount, setTotalPageCount] = useState()

    const [totalCount, setTotalCount] = useState('')
    const [sourceIpCount, setSourceIpCount] = useState([])
    const [sourcePortCount, setSourcePortCount] = useState()
    const [destinationPortCount, setDestinationPortCount] = useState()
    const [destinationIpCount, setDestinationIpCount] = useState()
    const [tableData, setTableData] = useState([])

    const [eventCateLabel, setEventCateLabel] = useState([]);
    const [eventCateData, setEventCateData] = useState([]);

    // event type
    const [eventTypeLabel, setEventTypeLabel] = useState([])
    const [eventTypeData, setEventTypeData] = useState([])
    const [doc, setDoc] = useState(null)

    // event type
    const [loggedUserLabel, setLoggedUserLabel] = useState([])
    const [loggedUserData, setLoggedUserData] = useState([])

    // source country
    const [sourceCountryLabel, setSourceCountryLabel] = useState([])
    const [sourceCountryData, setSourceCountryData] = useState([])

    // geo chart 
    const [geoSourceChart, setGeoSourceChart] = useState([])
    const [geoDestinationChart, setDestinationChart] = useState([])

    // destination country
    const [destinationCountryLabel, setDestinationCountryLabel] = useState([])
    const [destinationCountryData, setDestinationCountryData] = useState([])
    const SourcechartRef = useRef();
    const DestCreatedchartRef = useRef();
    // states for filter
    const [showAddFilter, setShowAddFilter] = useState(false);
    const [filter, setFilter] = useState([]);
    const [dateFilter, setDateFilter] = useState({
        from: moment().subtract(1, "hour")?.format("yyyy-MM-DDTHH:mm"),
        to: moment().format("yyyy-MM-DDTHH:mm"),
        f: moment().subtract(1, "hour")?.format("yyyy-MM-DDTHH:mm"),
        e: moment().format("yyyy-MM-DDTHH:mm"),
        range: 60
    });
    const [showInlineFilter, setShowInlineFilter] = useState({
        show: false,
        index: 0,
    });

    // loaders
    const [eventCateLoader, setEventCateLoader] = useState(true)
    const [eventTypeLoader, setEventTypeLoader] = useState(true)
    const [sourceCountryLoader, setSourceCountryLoader] = useState(true)
    const [destinationCountryLoader, setDestinationCountryLoader] = useState(true)
    const [loggedUserLoader, setLoggedUserLoader] = useState(true)

    // console.log("LABELL :", eventCateLabel)
    const [loadTableData, setLoadTableData] = useState(true)


    const [isTableTabSelected, setIsTableTabSelected] = useState(false);

    // manage column
    const [showManageColumn, setShowManageColumn] = useState(false);
    const [checkedItems, setCheckedItems] = useState([])
    const [manageDatas, setManageDatas] = useState([
        { text: "Event Category", value: "cybEventCategory", isSelected: true },
        { text: "Event Type", value: "cybEventType", isSelected: true },
        { text: "Destination IP", value: "dipv4", isSelected: true },
        { text: "Destination Port", value: "dport", isSelected: true },
        { text: "Destination Country", value: "cybDestCountry", isSelected: true },
        { text: "Source IP", value: "sipv4", isSelected: true },
        { text: "Source Port", value: "sport", isSelected: true },
        { text: "Source Country", value: "cybSourceCountry", isSelected: true },
        { text: "Bytes In", value: "bytesin", isSelected: false },
        { text: "Bytes Out", value: "bytesout", isSelected: false },
        { value: "severity", text: "Severity", isSelected: false },
        { value: "type", text: "Type", isSelected: false },
        { value: "subtype", text: "Subtype", isSelected: false },
        { value: "objectname", text: "Object Name", isSelected: false },
        { value: "url", text: "URL", isSelected: false },
        { value: "action", text: "Action", isSelected: false },
        { value: "dstregion", text: "Destination Region", isSelected: false },
        { value: "devname", text: "Dev Name", isSelected: false },
        { value: "srchwvendor", text: "Vendor", isSelected: false },
        { value: "osname", text: "OS", isSelected: false },
        { value: "srcswversion", text: "OS Version", isSelected: false },
        { value: "user", text: "User", isSelected: false },


    ])



    useEffect(() => {
        let storedData = localStorage.getItem('ManageColumn.investigate.cykit.firewall')

        if (storedData) {
            setManageDatas(JSON.parse(storedData))
        } else {
            setManageDatas(manageDatas)
        }

    }, [manageDatas])


    // let manageDatas = [
    //     { text: "Event Category", value: "cybEventCategory", isSelected: true },
    //     { text: "Event Type", value: "cybEventType", isSelected: true },
    //     { text: "Destination IP", value: "dipv4", isSelected: true },
    //     { text: "Destination Port", value: "dport", isSelected: true },
    //     { text: "Destination Country", value: "cybDestCountry", isSelected: true },
    //     { text: "Source IP", value: "sipv4", isSelected: true },
    //     { text: "Source Port", value: "sport", isSelected: true },
    //     { text: "Source Country", value: "cybSourceCountry", isSelected: true },
    //     { text: "Bytes In", value: "bytesin", isSelected: true },
    //     { text: "Bytes Out", value: "bytesout", isSelected: true },
    // ]

    const ref = useOnclickOutside(() => {
        setShowAddFilter(false);
        setShowManageColumn(false)

    });
    const [expclickedNode, setExpClickedNode] = useState("")
    const [expclose, setExpClose] = useState(false)
    // const SipChart = {
    //     labels: Labels,
    //     datasets: [
    //         {
    //             label: "Community Feeds",
    //             data: dataChart,
    //             backgroundColor: [
    //                 "rgba(255, 99, 132, 0.8)",
    //                 "rgba(54, 162, 235, 0.8)",
    //                 "rgba(255, 206, 86, 0.8)",
    //                 "rgba(75, 192, 192, 0.8)",
    //                 "rgba(153, 102, 255, 0.8)",
    //                 "rgba(255, 159, 64, 0.8)",
    //             ],
    //             borderColor: [
    //                 "rgba(255, 99, 132, 1)",
    //                 "rgba(54, 162, 235, 1)",
    //                 "rgba(255, 206, 86, 1)",
    //                 "rgba(75, 192, 192, 1)",
    //                 "rgba(153, 102, 255, 1)",
    //                 "rgba(255, 159, 64, 1)",
    //             ],
    //             borderWidth: 0,
    //             hoverOffset: 0,
    //         },
    //     ],
    // };

    // const options = {
    //     responsive: true,
    //     plugins: {
    //         legend: {
    //             display: false,
    //             position: 'top',
    //         },
    //         title: {
    //             display: true,
    //             // text: 'Chart.js Bar Chart',
    //         },
    //     },
    //     scales: {
    //         y: {
    //             beginAtZero: true,
    //             grid: {
    //                 display: false, // disable y axis inside grid lines
    //             },
    //         },
    //         x: {
    //             grid: {
    //                 display: false, // disable x axis inside grid lines
    //             },
    //         },
    //     },
    // };

    // const data = {
    //     labels: eventCateLabel,
    //     datasets: [
    //         {
    //             label: 'Event Category',
    //             data: eventCateData,
    //             backgroundColor: 'rgba(255, 99, 132, 0.5)',
    //         },
    //     ],
    // };


    const barChartoptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: true,

            },
        },
    };

    // for filter
    let count = [];
    let count2;
    const module = 'investigation'
    const tab = 'cykit-firewall'


    async function getCyTableData2(filter, date, perPage, page) {
        let data = getFirwallTableQuery(filter, date, perPage, "desc", page);

        return await fpostDl(
            `${localStorage.getItem("DATALAKE_URL")}${localStorage.getItem("INVESTIGATE_CYF")}/_search?track_total_hits=true`,
            data
        );
    }

    const getFilteredTable = (filter, date = {}) => {
        setLoadTableData(true);

        if (filter || date?.to) {
            getCyTableData2(filter, date, perPage, (page - 1) * perPage).then(({ data: { hits } }) => {
                hits.hits ? setTableData(hits.hits) : setTableData([])
                setTotalPageCount(hits.total.value)
                setLoadTableData(false)
            }).catch((err) => {
                setTableData([])
                setLoadTableData(false)
                console.log("Error", err)
            })
        }
        else {
            getCyTableData().then(({ data: { hits } }) => {
                hits.hits ? setTableData(hits.hits) : setTableData([])
                setTotalPageCount(hits.total.value)
                setLoadTableData(false)
            }).catch((err) => {
                setTableData([])
                setLoadTableData(false)
                console.log("Error", err)
            })
        }
    }

    useMemo(() => {
        const storedFilter = JSON.parse(localStorage.getItem("filters"));
        const currentPageFilter = storedFilter?.[module]?.[tab]?.fields
        const currentPageDateFilter = storedFilter?.[module]?.[tab]?.date
        console.log("currentPageFilter", currentPageFilter, currentPageDateFilter)
        if (currentPageFilter?.length || currentPageDateFilter?.to) {
            count = currentPageFilter;
            count2 = currentPageDateFilter?.to
            setDateFilter(currentPageDateFilter)

            setFilter(currentPageFilter);
            getFilteredTable(currentPageFilter, currentPageDateFilter);
        }
    }, []);

    useEffect(() => {
        // manageDatas.map((item) => {
        //     item.isSelected = false
        // })

        setLoadTableData(true)
        setEventCateLoader(true)
        setEventTypeLoader(true)
        setSourceCountryLoader(true)
        setDestinationCountryLoader(true)
        setLoggedUserLoader(true)
        getTotalCounts(filter, dateFilter).then(({ data: { hits } }) => {
            setTotalCount(hits?.total?.value)
        }).catch((err) => {
            console.log("Error", err)
        })


        // counts

        getCounts("sipv4", filter, dateFilter).then(({ data: { aggregations: { 2: { value, buckets } } } }) => {
            setSourceIpCount(value)
        }).catch((err) => {
            console.log("Error", err)
        })

        getCounts("sport", filter, dateFilter).then(({ data: { aggregations: { 2: { value, buckets } } } }) => {
            setSourcePortCount(value)
        }).catch((err) => {
            console.log("Error", err)
        })

        getCounts("dipv4", filter, dateFilter).then(({ data: { aggregations: { 2: { value, buckets } } } }) => {
            setDestinationIpCount(value)
        }).catch((err) => {
            console.log("Error", err)
        })

        getCounts("dport", filter, dateFilter).then(({ data: { aggregations: { 2: { value, buckets } } } }) => {
            setDestinationPortCount(value)
        }).catch((err) => {
            console.log("Error", err)
        })

        // graph
        // cyb Event category (Top 10)
        getWidgetsData("cybEventCategory", 10, "asc", filter, dateFilter).then(({ data: { aggregations: { 2: { buckets } } } }) => {
            let label = [];
            let data = [];
            if (buckets.length > 0) {
                buckets.map((item) => {
                    label.push(item.key === "" ? "--" : item.key);
                    data.push(item.doc_count);
                    setEventCateLabel(label);
                    setEventCateData(data);
                });
                setEventCateLoader(false)
            }
            else {
                setEventCateLabel(label);
                setEventCateData(data);
                setEventCateLoader(false)

            }
        }).catch((err) => {
            console.log("Error", err)
        })

        // cyb Event category (Least 10)
        getWidgetsData("cybEventCategory", 10, "desc", filter, dateFilter).then(({ data: { aggregations: { 2: { buckets } } } }) => {
            let label = [];
            let data = [];
            if (buckets.length > 0) {
                buckets.map((item) => {
                    label.push(item.key === "" ? "--" : item.key);
                    data.push(item.doc_count);
                    // setLabels(label);
                    // setDataChart(data);
                });
            }
        }).catch((err) => {
            console.log("Error", err)
        })

        // cyb Event Type (Top 10)
        getWidgetsData("cybEventType", 10, "asc", filter, dateFilter).then(({ data: { aggregations: { 2: { buckets } } } }) => {
            let label = [];
            let data = [];
            if (buckets.length > 0) {
                buckets.map((item) => {
                    label.push(item.key === "" ? "--" : item.key);
                    data.push(item.doc_count);
                    setEventTypeLabel(label)
                    setEventTypeData(data)
                });
                setEventTypeLoader(false)
            }
            else {
                setEventTypeLabel(label)
                setEventTypeData(data)
                setEventTypeLoader(false)

            }
        }).catch((err) => {
            console.log("Error", err)
        })

        // cyb Event Type (Least 10)
        getWidgetsData("cybEventType", 10, "desc", filter, dateFilter).then(({ data: { aggregations: { 2: { buckets } } } }) => {
            let label = [];
            let data = [];
            if (buckets.length > 0) {
                buckets.map((item) => {
                    label.push(item.key === "" ? "--" : item.key);
                    data.push(item.doc_count);
                    // setLabels(label);
                    // setDataChart(data);
                });
            }
        }).catch((err) => {
            console.log("Error", err)
        })

        // cyb user login ( Top 10 )
        getWidgetsData("login", 10, "desc", filter, dateFilter).then(({ data: { aggregations: { 2: { buckets } } } }) => {
            let label = [];
            let data = [];
            if (buckets.length > 0) {
                buckets.map((item) => {
                    label.push(item.key === "" ? "--" : item.key);
                    data.push(item.doc_count);
                    setLoggedUserLabel(label)
                    setLoggedUserData(data)
                });
                setLoggedUserLoader(false)
            }
            else {
                setLoggedUserLabel(label)
                setLoggedUserData(data)
                setLoggedUserLoader(false)

            }
        }).catch((err) => {
            console.log("Error", err)
        })

        // cyb user login ( Least 10 )
        getWidgetsData("login", 10, "desc", filter, dateFilter).then(({ data: { aggregations: { 2: { buckets } } } }) => {
            let label = [];
            let data = [];
            if (buckets.length > 0) {
                buckets.map((item) => {
                    label.push(item.key === "" ? "--" : item.key);
                    data.push(item.doc_count);
                    // setLabels(label);
                    // setDataChart(data);
                });
            }
        }).catch((err) => {
            console.log("Error", err)
        })

        // cyb source country ( Top 10 )
        getWidgetsData("cybSourceCountry", 20, "desc", filter, dateFilter).then(({ data: { aggregations: { 2: { buckets } } } }) => {
            let label = [];
            let data = [];
            if (buckets.length > 0) {
                buckets.map((item) => {
                    label.push(item.key === "" ? "--" : item.key);
                    data.push(item.doc_count);

                });
                setSourceCountryLabel(label)
                setSourceCountryData(data)
                setSourceCountryLoader(false)
                let geoData = [];
                let locations = label
                locations.forEach((location) => {

                    location.key !== "NA" && geoData.push([location])
                })
                geoData = [["test"], ...geoData]

                setGeoSourceChart(geoData)
            }
            else {
                setSourceCountryLabel([])
                var geoData = []
                geoData = [["test"], ...geoData]
                setGeoSourceChart(geoData)
                setSourceCountryLoader(false)

            }

        }).catch((err) => {
            console.log("Error", err)
        })

        // cyb source country ( Least 10 )
        // getWidgetsData("cybSourceCountry", 20, "desc", filter, dateFilter).then(({ data: { aggregations: { 2: { buckets } } } }) => {
        //     let label = [];
        //     let data = [];
        //     if (buckets.length > 0) {
        //         buckets.map((item) => {
        //             label.push(item.key === "" ? "--" : item.key);
        //             data.push(item.doc_count);
        //             // setLabels(label);
        //             // setDataChart(data);
        //         });
        //     }
        // }).catch((err) => {
        //     console.log("Error", err)
        // })

        // cyb destination country ( Top 10 )
        getWidgetsData("cybDestCountry", 20, "desc", filter, dateFilter).then(({ data: { aggregations: { 2: { buckets } } } }) => {
            let label = [];
            let data = [];
            if (buckets.length > 0) {
                buckets.map((item) => {
                    label.push(item.key === "" ? "--" : item.key);
                    data.push(item.doc_count);
                });
                setDestinationCountryLabel(label)
                setDestinationCountryData(data)
                setDestinationCountryLoader(false)
                let geoData = [];
                let locations = label
                locations.forEach((location) => {
                    location.key !== "NA" && geoData.push([location])
                })
                var test = locations.filter(obj => obj.key === "Internal")
                geoData = [["test"], ...geoData]

                setDestinationChart(geoData)
            }
            else {
                setDestinationCountryLabel([])
                var geoData = []
                geoData = [["test"], ...geoData]
                setDestinationChart(geoData)
                setDestinationCountryLoader(false)

            }

        }).catch((err) => {
            console.log("Error", err)
        })

        // cyb destination country ( Least 10 )
        // getWidgetsData("cybDestCountry", 20, "desc", filter, dateFilter).then(({ data: { aggregations: { 2: { buckets } } } }) => {
        //     let label = [];
        //     let data = [];
        //     if (buckets.length > 0) {
        //         buckets.map((item) => {
        //             label.push(item.key === "" ? "--" : item.key);
        //             data.push(item.doc_count);
        //             // setLabels(label);
        //             // setDataChart(data);
        //         });
        //     }
        // }).catch((err) => {
        //     console.log("Error", err)
        // })


        // getSourceIpData().then(({ data: { aggregations: { 2: { sum_other_doc_count, buckets } } } }) => {
        //     // buckets && setSipUniqueCount(buckets)

        //     let label = [];
        //     let data = [];
        //     if (buckets.length > 0) {
        //         buckets.map((item) => {
        //             label.push(item.key === "" ? "Undefined" : item.key);
        //             data.push(item.doc_count);
        //             setLabels(label);
        //             setDataChart(data);
        //         });
        //     }
        // }).catch((err) => {
        //     console.log("Error", err)
        // })


        // getSourcePortsData().then(({ data: { aggregations: { 2: { sum_other_doc_count, buckets } } } }) => {
        //     sum_other_doc_count && setSourcePortCount(sum_other_doc_count)
        // }).catch((err) => {
        //     console.log("Error", err)
        // })

        // getDestinationPortsData().then(({ data: { aggregations: { 2: { sum_other_doc_count, buckets } } } }) => {
        //     sum_other_doc_count && setDestinationPortCount(sum_other_doc_count)
        //     console.log("RESS new :", sum_other_doc_count)
        // }).catch((err) => {
        //     alert("Errrrr")
        // })

        // getDestinationIpData().then(({ data: { aggregations: { 2: { sum_other_doc_count, buckets } } } }) => {
        //     sum_other_doc_count && setDestinationIpCount(sum_other_doc_count)
        //     console.log("DIPPP :", sum_other_doc_count)
        // }).catch((err) => {
        //     alert("faildeeesds")
        // })

        // setCurrentPageCount((page - 1) * perPage + perPage);

        if (!count?.length && !count2 && !filter.length && isEmptyObject(dateFilter)) {
            getCyTableData(100, "desc", (page - 1) * perPage).then(({ data: { hits } }) => {
                setTotalPageCount(hits.total.value)
                hits.hits ? setTableData(hits.hits) : setTableData([])
                setLoadTableData(false)
            }).catch((err) => {
                console.log("Error", err)
                setTableData([])
                setLoadTableData(false)
            })
        }
        else {
            getFilteredTable(filter, dateFilter)
        }
    }, [page, filter, dateFilter])

    const MapClick = (field, label, value) => {
        if (field && label && value) {
            var temp = {
                column: { label: label, value: field },
                type: "is",
                selectedValue: value[0],
                from: "",
                to: "",
                isinline: false
            }
            let storedFilter = JSON.parse(localStorage.getItem("filters"));
            let temp2 = {
                ...storedFilter,
                [module]: {
                    [tab]: {
                        fields: [temp]
                    }
                }
            };
            localStorage.setItem("filters", JSON.stringify(temp2));
            setFilter([...filter, temp])
        }
    }

    function handleTabSelect(key) {

        if (key === "table")
            setIsTableTabSelected(true)
        else
            setIsTableTabSelected(false)
    }

    const handleCheckboxChange = (e) => {
        let { value, checked } = e.target;

        if (checked) {
            setCheckedItems([...checkedItems, { text: value, value: value }])
        } else {
            setCheckedItems(checkedItems.filter((item) => item.value !== value))
        }

        let index = manageDatas.findIndex(x => x.value === value)
        manageDatas[index].isSelected = !manageDatas[index].isSelected
        setManageDatas(manageDatas)
        localStorage.setItem("ManageColumn.investigate.cykit.firewall", JSON.stringify(manageDatas))
    }


    return (
        <div className="col-12 position-relative">

            <div
                className="d-flex flex-row align-items-center justify-content-end ml-auto col-5"
                style={styles.filterWrap}
            >
                <div className="position-relative">
                    <Button
                        className="mx-2"
                        ref={ref}
                        onClick={() => { setShowAddFilter(!showAddFilter); setShowManageColumn(false) }
                        }
                    >
                        Add filter
                    </Button>
                    {showAddFilter && (
                        <AddFilter
                            filter={filter}
                            setFilter={setFilter}
                            dateFilter={dateFilter}
                            setDateFilter={setDateFilter}
                            setShowAddFilter={setShowAddFilter}
                            getFilteredTable={getFilteredTable}
                            setPage={setPage}

                            columnValues={[
                                { value: "dipv4", label: "Destination IP" },
                                { value: "sipv4", label: "Source IP" },
                                { value: "sport", label: "Source Port" },
                                { value: "dport", label: "Destination Port" },
                                { value: "cybSourceCountry", label: "Source Country", isWildCard: true },
                                { value: "cybDestCountry", label: "Destination Country", isWildCard: true },
                                { value: "cybEventCategory", label: "Event Category", isWildCard: true },
                                { value: "cybEventType", label: "Event Type", isWildCard: true },
                                { value: "severity", label: "Severity", isWildCard: true },
                                { value: "type", label: "Type", isWildCard: true },
                                { value: "subtype", label: "Subtype", isWildCard: true },
                                { value: "objectname", label: "Object Name", isWildCard: true },
                                { value: "url", label: "URL", isWildCard: true },
                                { value: "action", label: "Action", isWildCard: true },
                                { value: "dstregion", label: "Destination Region", isWildCard: true },
                                { value: "devname", label: "Dev Name", isWildCard: true },
                                { value: "srchwvendor", label: "Vender", isWildCard: true },
                                { value: "osname", label: "OS", isWildCard: true },
                                { value: "srcswversion", label: "OS Version", isWildCard: true },
                                { value: "event.original", label: "Raw Field", isWildCard: true },
                                { value: "user", label: "User", isWildCard: true },

                            ]}
                            module={module}
                            tab={tab}
                        />
                    )}
                </div>

                {isTableTabSelected && (
                    <div className="position-relative">
                        <Button
                            variant="light"
                            // className="mx-2"
                            ref={ref}
                            onClick={() => { setShowManageColumn(!showManageColumn); setShowAddFilter(false) }}
                        >
                            Manage columns
                        </Button>
                        {showManageColumn && (
                            <div
                                className="more-table-field-container shadow bg-white rounded-3 p-3 mt-3 ignore-onclickoutside"
                                style={{
                                    position: "absolute",
                                    right: 0,
                                    zIndex: "111",
                                    minWidth: "20rem",
                                    maxHeight: "50vh",
                                    overflow: "auto"
                                }}
                            >
                                <div className="table-fields">
                                    {manageDatas?.map((item) => (
                                        <div className="d-flex align-items-center py-2 gap-2">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value={item.value}
                                                    onChange={handleCheckboxChange}
                                                    checked={item.isSelected ? true : false}
                                                // checked={investigationTableHead.some((head) => head.value.includes(item.value))}
                                                />
                                                <label className="form-check-label" for="flexCheckDefault">
                                                    {item.text}
                                                </label>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                )}


                {isTableTabSelected && (
                    <div className="position-relative ms-2">
                        <CykitINVExport tabledata={getCyTableData2} type={"firewall"} filter={filter} dateFilter={dateFilter} columns={manageDatas} />
                    </div>
                )}


                {isTableTabSelected && (
                    <Pagination
                        page={page}
                        setPage={setPage}
                        perPage={perPage}
                        currentPageCount={currentPageCount}
                        totalDataCounts={totalPageCount}
                    />
                )}



            </div>

            {/* table datas */}
            <div className="col-12 d-flex flex-column p-4 rounded-3 bg-white position-relative">

                <Tabs
                    defaultActiveKey="dashboard"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    onSelect={handleTabSelect}
                // onSelect={(e) => setActiveTab(e)}
                >
                    <Tab eventKey="dashboard" title="Overview">
                        <div className='col-12 d-flex flex-column'>
                            <FilterList
                                filter={filter}
                                setFilter={setFilter}
                                dateFilter={dateFilter}
                                setDateFilter={setDateFilter}
                                getFilteredTable={getFilteredTable}
                                module={module}
                                tab={tab}
                            />
                            <div className="col-12 d-flex flex-column flex-fill p-0 rounded-3 bg-white gap-3">
                                <div className="d-flex flex-column gap-3" style={styles.subSection1}>
                                    <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">
                                        <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                                            <div>
                                                <h4>{totalCount ? totalCount : "--"}</h4>
                                                <span>Total count</span>
                                            </div>
                                        </div>
                                        <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                                            <div>
                                                <h4>{sourceIpCount ? sourceIpCount : "--"}</h4>
                                                <span>Unique Source IPs</span>
                                            </div>
                                        </div>
                                        <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                                            <div>
                                                <h4>{sourcePortCount ? sourcePortCount : "--"}</h4>
                                                <span>Unique Source Ports</span>
                                            </div>
                                        </div>
                                        <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                                            <div>
                                                <h4>{destinationIpCount ? destinationIpCount : "--"}</h4>
                                                <span>Unique Destination IPs</span>
                                            </div>
                                        </div>
                                        <div className="flex-fill rounded-3 p-3" style={styles.overviewTile}>
                                            <div>
                                                <h4>{destinationPortCount ? destinationPortCount > 65535 ? 65535 : destinationPortCount : "--"}</h4>
                                                <span>Unique Destination Ports</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">
                                        <div className="col-6 p-4 rounded-3 border">
                                            <h5>Event Category</h5>
                                            {eventCateLoader ? (<PlainCardLoader width="200px" />) : (
                                                <div className='col-12'>
                                                    {eventCateData.length || eventCateLabel.length !== 0 ? (
                                                        <BarGraph options={barChartoptions} ChartLabel={eventCateLabel} ChartData={eventCateData} ChartTitle={"Event Category"} module={module} tab={tab} field={"cybEventCategory"} label={"Event Category"} setfilter={setFilter} filter={filter} />
                                                    ) : (
                                                        <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                            <NoDataFound errorText={"No data found"} />
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>

                                        <div className="flex-fill p-4 rounded-3 border" >
                                            <h5>Event Type</h5>
                                            {eventTypeLoader ? (<PlainCardLoader width="200px" />) : (
                                                <div className='col-12'>
                                                    {eventTypeLabel.length || eventTypeData.length !== 0 ? (
                                                        <BarGraph options={barChartoptions} ChartLabel={eventTypeLabel} ChartData={eventTypeData} ChartTitle={"Event Type"} module={module} tab={tab} field={"cybEventType"} label={"Event Type"} setfilter={setFilter} filter={filter} />
                                                    ) : (
                                                        <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                            <NoDataFound errorText={"No data found"} />
                                                        </div>
                                                    )}
                                                </div>
                                            )}

                                        </div>
                                    </div>

                                    <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">
                                        <div className="col-6 p-4 rounded-3 border">
                                            <h5>Traffic From Internet</h5>
                                            {sourceCountryLoader ? (<PlainCardLoader width="200px" />) : (
                                                <>
                                                    {sourceCountryLabel.length || sourceCountryData.length !== 0 ? (
                                                        // <BarGraph ChartLabel={sourceCountryLabel} ChartData={sourceCountryData} ChartTitle={"Source Country"} />
                                                        <span className="modal-text">
                                                            <Chart
                                                                chartEvents={[
                                                                    {
                                                                        eventName: "select",
                                                                        callback: ({ chartWrapper }) => {
                                                                            const chart = chartWrapper.getChart();
                                                                            const selection = chart.getSelection();
                                                                            if (selection.length === 0) return;

                                                                            const region = geoSourceChart[selection[0].row + 1];
                                                                            MapClick("cybSourceCountry", "Source Country", region)
                                                                        },
                                                                    },
                                                                ]}
                                                                chartType="GeoChart"
                                                                width="100%"
                                                                height="300px"
                                                                data={geoSourceChart}
                                                            />
                                                        </span>
                                                    ) : (
                                                        <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                            <NoDataFound errorText={"No data found"} />
                                                        </div>
                                                    )}
                                                </>
                                            )}

                                        </div>

                                        <div className="flex-fill p-4 rounded-3 border" >
                                            <h5>Traffic To Internet</h5>
                                            {destinationCountryLoader ? (<PlainCardLoader width="200px" />) : (
                                                <>
                                                    {destinationCountryLabel.length || destinationCountryData.length !== 0 ? (
                                                        // <BarGraph ChartLabel={destinationCountryLabel} ChartData={destinationCountryData} ChartTitle={"Destination Country"} />
                                                        <span className="modal-text">
                                                            <Chart
                                                                chartEvents={[
                                                                    {
                                                                        eventName: "select",
                                                                        callback: ({ chartWrapper }) => {
                                                                            const chart = chartWrapper.getChart();
                                                                            const selection = chart.getSelection();
                                                                            if (selection.length === 0) return;
                                                                            const region = geoDestinationChart[selection[0].row + 1];
                                                                            MapClick("cybDestCountry", "Destination Country", region)
                                                                        },
                                                                    },
                                                                ]}
                                                                chartType="GeoChart"
                                                                width="100%"
                                                                height="300px"
                                                                data={geoDestinationChart}
                                                            />
                                                        </span>
                                                    ) : (
                                                        <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                            <NoDataFound errorText={"No data found"} />
                                                        </div>
                                                    )}
                                                </>
                                            )}

                                        </div>
                                    </div>

                                    <div className="col-12 d-flex flex-row gap-3 p-0 rounded-3 bg-white">
                                        <div className="col-6 p-4 rounded-3 border" >
                                            <h5>Users</h5>
                                            {loggedUserLoader ? (<PlainCardLoader width="200px" />) : (
                                                <div className='col-12'>
                                                    {loggedUserLabel.length || loggedUserData.length !== 0 ? (
                                                        <BarGraph options={barChartoptions} ChartLabel={loggedUserLabel} ChartData={loggedUserData} ChartTitle={"Logged Users"} module={module} tab={tab} field={"login"} label={"User Name"} setfilter={setFilter} filter={filter} />
                                                    ) : (
                                                        <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                            <NoDataFound errorText={"No data found"} />
                                                        </div>
                                                    )}
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="table" title="Details">
                        <div className="col-12">

                            <div className="" style={styles.tableWrap}>
                                <FilterList
                                    filter={filter}
                                    setFilter={setFilter}
                                    dateFilter={dateFilter}
                                    setDateFilter={setDateFilter}
                                    getFilteredTable={getFilteredTable}
                                    module={module}
                                    tab={tab}
                                />
                                {loadTableData ? (
                                    <TableLoader rowCount={30} />
                                ) : (
                                    <>
                                        {tableData.length !== 0 ? (
                                            <Table borderless hover>
                                                <thead className="fixed-head">
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>Time</th>
                                                        {/* <th>Event Category</th>
                                                        <th>Event Type</th> */}
                                                        {manageDatas.map((item) => (
                                                            item.isSelected === true &&
                                                            <th>{item.text}</th>
                                                        ))}
                                                        {/* <th>Destination IP</th>
                                                        <th>Destination Port</th>
                                                        <th>Destination Country</th>
                                                        <th>Source IP</th>
                                                        <th>Source Port</th>
                                                        <th>Source Country</th>
                                                        <th>Bytes In</th>
                                                        <th>Bytes Out</th>
                                                         */}
                                                        <th></th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {tableData?.map((item, index) => (
                                                        <tr
                                                            onMouseOver={() =>
                                                                setShowInlineFilter({ show: true, index: index })
                                                            }
                                                            onMouseOut={() =>
                                                                setShowInlineFilter({ show: false, index: index })
                                                            }
                                                        >
                                                            {/* <td className="font-monospace">{item._source?.dipv4}</td> */}
                                                            <td className="font-monospace">{getDateFromTimestamp(item._source?.["@timestamp"])}</td>
                                                            <td className="font-monospace">{getTimeFromTimestamp(item._source?.["@timestamp"])}</td>
                                                            {manageDatas?.map((data) => (
                                                                data.value === "cybEventCategory" && data.isSelected === true ? (
                                                                    <td>
                                                                        <span className="font-monospace">
                                                                            {item._source?.cybEventCategory}
                                                                            {showInlineFilter.show &&
                                                                                showInlineFilter.index === index && (
                                                                                    <div className="inline-filter-wrap">
                                                                                        <InlineFilter
                                                                                            filter={filter}
                                                                                            setFilter={setFilter}
                                                                                            column={{
                                                                                                value: "cybEventCategory",
                                                                                                label: "Event Category",
                                                                                            }}
                                                                                            value={item?._source.cybEventCategory}
                                                                                            getFilteredTable={getFilteredTable}
                                                                                            module={module}
                                                                                            tab={tab}
                                                                                            dateFilter={dateFilter}
                                                                                            setPage={setPage}

                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                        </span>
                                                                    </td>
                                                                ) : data.value === "cybEventType" && data.isSelected === true ? (
                                                                    <td>
                                                                        <span className="font-monospace">
                                                                            {item._source?.cybEventType}
                                                                            {showInlineFilter.show &&
                                                                                showInlineFilter.index === index && (
                                                                                    <div className="inline-filter-wrap">
                                                                                        <InlineFilter
                                                                                            filter={filter}
                                                                                            setFilter={setFilter}
                                                                                            column={{
                                                                                                value: "cybEventType",
                                                                                                label: "Event Type",
                                                                                            }}
                                                                                            value={item?._source.cybEventType}
                                                                                            getFilteredTable={getFilteredTable}
                                                                                            module={module}
                                                                                            tab={tab}
                                                                                            dateFilter={dateFilter}
                                                                                            setPage={setPage}

                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                        </span>
                                                                    </td>
                                                                ) : data.value === "dipv4" && data.isSelected === true ? (
                                                                    <td>
                                                                        <span className="font-monospace">
                                                                            {item?._source?.dipv4}
                                                                            {showInlineFilter.show &&
                                                                                showInlineFilter.index === index && (
                                                                                    <div className="inline-filter-wrap">
                                                                                        <InlineFilter
                                                                                            filter={filter}
                                                                                            setFilter={setFilter}
                                                                                            column={{
                                                                                                value: "dipv4",
                                                                                                label: "Destination IP",
                                                                                            }}
                                                                                            value={item?._source.dipv4}
                                                                                            getFilteredTable={getFilteredTable}
                                                                                            module={module}
                                                                                            tab={tab}
                                                                                            dateFilter={dateFilter}
                                                                                            setPage={setPage}

                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                        </span>
                                                                    </td>
                                                                ) : data.value === "dport" && data.isSelected === true ? (
                                                                    <td className="font-monospace">
                                                                        <span className="font-monospace">
                                                                            {item._source?.dport}
                                                                            {showInlineFilter.show &&
                                                                                showInlineFilter.index === index && (
                                                                                    <div className="inline-filter-wrap">
                                                                                        <InlineFilter
                                                                                            filter={filter}
                                                                                            setFilter={setFilter}
                                                                                            column={{
                                                                                                value: "dport",
                                                                                                label: "Destination Port",
                                                                                            }}
                                                                                            value={item?._source.dport}
                                                                                            getFilteredTable={getFilteredTable}
                                                                                            module={module}
                                                                                            tab={tab}
                                                                                            dateFilter={dateFilter}
                                                                                            setPage={setPage}

                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                        </span>
                                                                    </td>
                                                                ) : data.value === "cybDestCountry" && data.isSelected === true ? (
                                                                    <td>
                                                                        <span className="font-monospace">
                                                                            {item._source?.cybDestCountry}
                                                                            {showInlineFilter.show &&
                                                                                showInlineFilter.index === index && (
                                                                                    <div className="inline-filter-wrap">
                                                                                        <InlineFilter
                                                                                            filter={filter}
                                                                                            setFilter={setFilter}
                                                                                            column={{
                                                                                                value: "cybDestCountry",
                                                                                                label: "Destination Country",
                                                                                            }}
                                                                                            value={item?._source.cybDestCountry}
                                                                                            getFilteredTable={getFilteredTable}
                                                                                            module={module}
                                                                                            tab={tab}
                                                                                            dateFilter={dateFilter}
                                                                                            setPage={setPage}

                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                        </span>
                                                                    </td>
                                                                ) : data.value === "sipv4" && data.isSelected === true ? (
                                                                    <td>
                                                                        <span className="font-monospace">
                                                                            {item?._source?.sipv4}
                                                                            {showInlineFilter.show &&
                                                                                showInlineFilter.index === index && (
                                                                                    <div className="inline-filter-wrap">
                                                                                        <InlineFilter
                                                                                            filter={filter}
                                                                                            setFilter={setFilter}
                                                                                            column={{
                                                                                                value: "sipv4",
                                                                                                label: "Source IP",
                                                                                            }}
                                                                                            value={item?._source.sipv4}
                                                                                            getFilteredTable={getFilteredTable}
                                                                                            module={module}
                                                                                            tab={tab}
                                                                                            dateFilter={dateFilter}
                                                                                            setPage={setPage}

                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                        </span>
                                                                    </td>
                                                                ) : data.value === "" && data.isSelected === true ? (
                                                                    <td
                                                                        style={{ display: "flex", flexDirection: "row" }}
                                                                    >
                                                                        <span className="font-monospace">
                                                                            {item?._source?.sipv4}
                                                                            {showInlineFilter.show &&
                                                                                showInlineFilter.index === index && (
                                                                                    <div className="inline-filter-wrap">
                                                                                        <InlineFilter
                                                                                            filter={filter}
                                                                                            setFilter={setFilter}
                                                                                            column={{
                                                                                                value: "sipv4",
                                                                                                label: "Source IP",
                                                                                            }}
                                                                                            value={item?._source.sipv4}
                                                                                            getFilteredTable={getFilteredTable}
                                                                                            module={module}
                                                                                            tab={tab}
                                                                                            dateFilter={dateFilter}
                                                                                            setPage={setPage}

                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                        </span>
                                                                    </td>
                                                                ) : data.value === "sport" && data.isSelected === true ? (
                                                                    <td className="font-monospace">
                                                                        <span className="font-monospace">
                                                                            {item._source?.sport}
                                                                            {showInlineFilter.show &&
                                                                                showInlineFilter.index === index && (
                                                                                    <div className="inline-filter-wrap">
                                                                                        <InlineFilter
                                                                                            filter={filter}
                                                                                            setFilter={setFilter}
                                                                                            column={{
                                                                                                value: "sport",
                                                                                                label: "Source Port",
                                                                                            }}
                                                                                            value={item?._source.sport}
                                                                                            getFilteredTable={getFilteredTable}
                                                                                            module={module}
                                                                                            tab={tab}
                                                                                            dateFilter={dateFilter}
                                                                                            setPage={setPage}

                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                        </span>
                                                                    </td>
                                                                ) : data.value === "cybSourceCountry" && data.isSelected === true ? (
                                                                    <td>
                                                                        <span className="font-monospace">
                                                                            {item._source?.cybSourceCountry}
                                                                            {showInlineFilter.show &&
                                                                                showInlineFilter.index === index && (
                                                                                    <div className="inline-filter-wrap">
                                                                                        <InlineFilter
                                                                                            filter={filter}
                                                                                            setFilter={setFilter}
                                                                                            column={{
                                                                                                value: "cybSourceCountry",
                                                                                                label: "Source Country",
                                                                                            }}
                                                                                            value={item?._source.cybSourceCountry}
                                                                                            getFilteredTable={getFilteredTable}
                                                                                            module={module}
                                                                                            tab={tab}
                                                                                            dateFilter={dateFilter}
                                                                                            setPage={setPage}

                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                        </span>
                                                                    </td>
                                                                ) : data.value === "severity" && data.isSelected === true ? (
                                                                    <td>
                                                                        <span className="font-monospace">
                                                                            {item._source?.severity}
                                                                            {showInlineFilter.show &&
                                                                                showInlineFilter.index === index && (
                                                                                    <div className="inline-filter-wrap">
                                                                                        <InlineFilter
                                                                                            filter={filter}
                                                                                            setFilter={setFilter}
                                                                                            column={{
                                                                                                value: "severity",
                                                                                                label: "Severity",
                                                                                            }}
                                                                                            value={item?._source?.severity}
                                                                                            getFilteredTable={getFilteredTable}
                                                                                            module={module}
                                                                                            tab={tab}
                                                                                            dateFilter={dateFilter}
                                                                                            setPage={setPage}

                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                        </span>
                                                                    </td>
                                                                ) : data.value === "type" && data.isSelected === true ? (
                                                                    <td>
                                                                        <span className="font-monospace">
                                                                            {item._source?.type}
                                                                            {showInlineFilter.show &&
                                                                                showInlineFilter.index === index && (
                                                                                    <div className="inline-filter-wrap">
                                                                                        <InlineFilter
                                                                                            filter={filter}
                                                                                            setFilter={setFilter}
                                                                                            column={{
                                                                                                value: "type",
                                                                                                label: "Type",
                                                                                            }}
                                                                                            value={item?._source.type}
                                                                                            getFilteredTable={getFilteredTable}
                                                                                            module={module}
                                                                                            tab={tab}
                                                                                            dateFilter={dateFilter}
                                                                                            setPage={setPage}

                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                        </span>
                                                                    </td>
                                                                ) : data.value === "subtype" && data.isSelected === true ? (
                                                                    <td>
                                                                        <span className="font-monospace">
                                                                            {item._source?.subtype}
                                                                            {showInlineFilter.show &&
                                                                                showInlineFilter.index === index && (
                                                                                    <div className="inline-filter-wrap">
                                                                                        <InlineFilter
                                                                                            filter={filter}
                                                                                            setFilter={setFilter}
                                                                                            column={{
                                                                                                value: "subtype",
                                                                                                label: "Sub Type",
                                                                                            }}
                                                                                            value={item?._source.subtype}
                                                                                            getFilteredTable={getFilteredTable}
                                                                                            module={module}
                                                                                            tab={tab}
                                                                                            dateFilter={dateFilter}
                                                                                            setPage={setPage}

                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                        </span>
                                                                    </td>
                                                                ) : data.value === "objectname" && data.isSelected === true ? (
                                                                    <td>
                                                                        <span className="font-monospace">
                                                                            {item._source?.objectname}
                                                                            {showInlineFilter.show &&
                                                                                showInlineFilter.index === index && (
                                                                                    <div className="inline-filter-wrap">
                                                                                        <InlineFilter
                                                                                            filter={filter}
                                                                                            setFilter={setFilter}
                                                                                            column={{
                                                                                                value: "objectname",
                                                                                                label: "Object Name",
                                                                                            }}
                                                                                            value={item?._source.objectname}
                                                                                            getFilteredTable={getFilteredTable}
                                                                                            module={module}
                                                                                            tab={tab}
                                                                                            dateFilter={dateFilter}
                                                                                            setPage={setPage}

                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                        </span>
                                                                    </td>
                                                                ) : data.value === "url" && data.isSelected === true ? (
                                                                    <td>
                                                                        <span className="font-monospace">
                                                                            {item._source?.url}
                                                                            {showInlineFilter.show &&
                                                                                showInlineFilter.index === index && (
                                                                                    <div className="inline-filter-wrap">
                                                                                        <InlineFilter
                                                                                            filter={filter}
                                                                                            setFilter={setFilter}
                                                                                            column={{
                                                                                                value: "url",
                                                                                                label: "URL",
                                                                                            }}
                                                                                            value={item?._source.url}
                                                                                            getFilteredTable={getFilteredTable}
                                                                                            module={module}
                                                                                            tab={tab}
                                                                                            dateFilter={dateFilter}
                                                                                            setPage={setPage}

                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                        </span>
                                                                    </td>
                                                                ) : data.value === "action" && data.isSelected === true ? (
                                                                    <td>
                                                                        <span className="font-monospace">
                                                                            {item._source?.action}
                                                                            {showInlineFilter.show &&
                                                                                showInlineFilter.index === index && (
                                                                                    <div className="inline-filter-wrap">
                                                                                        <InlineFilter
                                                                                            filter={filter}
                                                                                            setFilter={setFilter}
                                                                                            column={{
                                                                                                value: "action",
                                                                                                label: "Action",
                                                                                            }}
                                                                                            value={item?._source.action}
                                                                                            getFilteredTable={getFilteredTable}
                                                                                            module={module}
                                                                                            tab={tab}
                                                                                            dateFilter={dateFilter}
                                                                                            setPage={setPage}

                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                        </span>
                                                                    </td>
                                                                ) : data.value === "bytesin" && data.isSelected === true ? (
                                                                    <td className="font-monospace">
                                                                        {item?._source.bytesin}
                                                                    </td>
                                                                ) : data.value === "bytesout" && data.isSelected === true ? (
                                                                    <td className="font-monospace">
                                                                        {item?._source.bytesout}
                                                                    </td>
                                                                ) : data.value === "dstregion" && data.isSelected === true ? (
                                                                    <td>
                                                                        {console.log("item._source?.dstregion", item._source)}
                                                                        <span className="font-monospace">
                                                                            {item._source?.dstregion}
                                                                            {showInlineFilter.show &&
                                                                                showInlineFilter.index === index && (
                                                                                    <div className="inline-filter-wrap">
                                                                                        <InlineFilter
                                                                                            filter={filter}
                                                                                            setFilter={setFilter}
                                                                                            column={{
                                                                                                value: "dstregion",
                                                                                                label: "Destination Region",
                                                                                            }}
                                                                                            value={item?._source.dstregion}
                                                                                            getFilteredTable={getFilteredTable}
                                                                                            module={module}
                                                                                            tab={tab}
                                                                                            dateFilter={dateFilter}
                                                                                            setPage={setPage}

                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                        </span>
                                                                    </td>
                                                                ) : data.value === "devname" && data.isSelected === true ? (
                                                                    <td>
                                                                        <span className="font-monospace">
                                                                            {item._source?.devname}
                                                                            {showInlineFilter.show &&
                                                                                showInlineFilter.index === index && (
                                                                                    <div className="inline-filter-wrap">
                                                                                        <InlineFilter
                                                                                            filter={filter}
                                                                                            setFilter={setFilter}
                                                                                            column={{
                                                                                                value: "devname",
                                                                                                label: "Dev Name",
                                                                                            }}
                                                                                            value={item?._source.devname}
                                                                                            getFilteredTable={getFilteredTable}
                                                                                            module={module}
                                                                                            tab={tab}
                                                                                            dateFilter={dateFilter}
                                                                                            setPage={setPage}

                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                        </span>
                                                                    </td>
                                                                ) : data.value === "srchwvendor" && data.isSelected === true ? (
                                                                    <td>
                                                                        <span className="font-monospace">
                                                                            {item._source?.srchwvendor}
                                                                            {showInlineFilter.show &&
                                                                                showInlineFilter.index === index && (
                                                                                    <div className="inline-filter-wrap">
                                                                                        <InlineFilter
                                                                                            filter={filter}
                                                                                            setFilter={setFilter}
                                                                                            column={{
                                                                                                value: "srchwvendor",
                                                                                                label: "Vendor",
                                                                                            }}
                                                                                            value={item?._source.srchwvendor}
                                                                                            getFilteredTable={getFilteredTable}
                                                                                            module={module}
                                                                                            tab={tab}
                                                                                            dateFilter={dateFilter}
                                                                                            setPage={setPage}

                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                        </span>
                                                                    </td>
                                                                ) : data.value === "osname" && data.isSelected === true ? (
                                                                    <td>
                                                                        <span className="font-monospace">
                                                                            {item._source?.osname}
                                                                            {showInlineFilter.show &&
                                                                                showInlineFilter.index === index && (
                                                                                    <div className="inline-filter-wrap">
                                                                                        <InlineFilter
                                                                                            filter={filter}
                                                                                            setFilter={setFilter}
                                                                                            column={{
                                                                                                value: "osname",
                                                                                                label: "OS Name",
                                                                                            }}
                                                                                            value={item?._source.osname}
                                                                                            getFilteredTable={getFilteredTable}
                                                                                            module={module}
                                                                                            tab={tab}
                                                                                            dateFilter={dateFilter}
                                                                                            setPage={setPage}

                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                        </span>
                                                                    </td>
                                                                ) : data.value === "srcswversion" && data.isSelected === true ? (
                                                                    <td>
                                                                        <span className="font-monospace">
                                                                            {item._source?.srcswversion}
                                                                            {showInlineFilter.show &&
                                                                                showInlineFilter.index === index && (
                                                                                    <div className="inline-filter-wrap">
                                                                                        <InlineFilter
                                                                                            filter={filter}
                                                                                            setFilter={setFilter}
                                                                                            column={{
                                                                                                value: "srcswversion",
                                                                                                label: "OS Version",
                                                                                            }}
                                                                                            value={item?._source.srcswversion}
                                                                                            getFilteredTable={getFilteredTable}
                                                                                            module={module}
                                                                                            tab={tab}
                                                                                            dateFilter={dateFilter}
                                                                                            setPage={setPage}

                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                        </span>
                                                                    </td>
                                                                ) : data.value === "user" && data.isSelected === true ? (
                                                                    <td>
                                                                        <span className="font-monospace">
                                                                            {item._source?.user}
                                                                            {showInlineFilter.show &&
                                                                                showInlineFilter.index === index && (
                                                                                    <div className="inline-filter-wrap">
                                                                                        <InlineFilter
                                                                                            filter={filter}
                                                                                            setFilter={setFilter}
                                                                                            column={{
                                                                                                value: "user",
                                                                                                label: "User",
                                                                                            }}
                                                                                            value={item?._source.user}
                                                                                            getFilteredTable={getFilteredTable}
                                                                                            module={module}
                                                                                            tab={tab}
                                                                                            dateFilter={dateFilter}
                                                                                            setPage={setPage}

                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                        </span>
                                                                    </td>
                                                                ): (<></>)

                                                            ))}


                                                            <td td >
                                                                <a onClick={() => { setExpClickedNode(item._id); setExpClose(false); setDoc(item) }} style={{ marginLeft: "20px" }}>
                                                                    <ArrowsOutSimple size={18} style={{ color: "#666666" }} />
                                                                </a>
                                                            </td>

                                                        </tr>
                                                    ))}

                                                </tbody>
                                            </Table>
                                        ) : (
                                            <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                <NoDataFound errorText={"No data found"} />
                                            </div>
                                        )}

                                    </>
                                )}
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </div>
            <ExpandedDocument doc={doc} setDoc={setDoc} clickedNode={expclickedNode} close={expclose} setClick={setExpClickedNode} url={datalakeURL} pattern={`${INVESTIGATE_CYF}/_search?track_total_hits=true`} req={fpostDl} />

        </div >
    )
}

export default CyFirewall